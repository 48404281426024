import React, { useContext, useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import { useLocation } from "react-router";
import ArcIcon from "../../shared/icon";
// import { MODULES } from "./constant";
import styles from "./topnav.module.scss";
import { AuthContext, AuthConsumer } from "@retisio/rbac";
import MODULES  from "./maintabs.json";
import getMenu from "../common.helper";
import { ArcPopover } from "@retisio/ui";
import { ConfigService } from "./config.service";

function TopNavigation() {
  const [menuData, setMenuData] = useState([]);
  const { user } = useContext(AuthContext);
  const cookies = new Cookies();
  useEffect(() => {
    let finalData;
    if (user.permissionIds) {
      let query={
        sortBy:'priority'
      };
      ConfigService.getApplications(query).then((data)=>{
        finalData = getMenu(data['applications'], user, true);
        console.log('finalData', finalData);
        setMenuData(finalData);
      }).catch(()=>{
        finalData = getMenu(MODULES.mainMenuList, user, true);
        setMenuData(finalData);
      });
    }
  }, [user]);

  const { pathname } = useLocation();

  const showTopNav = ()=>{
    if(pathname.indexOf("login") >-1|| pathname.indexOf("forgotpassword")>-1 || pathname.indexOf('resetpassword')>-1){
      return true;
    }
    return false;
  };


  const showLogout = (logout) => {
    return <div
      onClick={(e) => {
        e.preventDefault();
        pageLogout(e, logout);
      }}
    >
      Logout
    </div>;
  };

  const pageLogout = (e, logout) => {
    const lurl = cookies.get('lrurl');
    if (lurl) {
      window.location.href = lurl;
    } else {
      logout(e);
    }
  };

  
  return (
    <div
      className={`${styles.wrapper} ${
        showTopNav() ? styles.hide : styles.show
      }`}
    >
      <div className={styles.logo}>
        <ArcIcon name="logowhite" size={{ width: 100, height: 50 }} />
      </div>
      <div className={styles.menuItems}>
        <ul>
          {menuData.map((menu, i) => (
            menu?.active && <a key={"navbar " + i} href={`${menu.href}`} title={menu.appId}>
              <li
                className={
                  pathname.indexOf(menu.appId) > -1 ? styles.active : ""
                }
              >
                {menu.value}
              </li>
            </a>
          ))}
        </ul>
      </div>
      <div className={styles.userprofile}>
        <ArcIcon name="bell" size={{ width: 24, height: 24 }} />
        <AuthConsumer>
          {({ user }) =>
            user &&
            user.profile && (
              <span>
                {user.profile.firstName + " " + user.profile.lastName}
              </span>
            )
          }
        </AuthConsumer>
        <ArcPopover
          content={
            <AuthConsumer>
              {({logout})=>showLogout(logout)}
            </AuthConsumer>
          }
          placement="bottom"
          trigger="click"
        >
          <ArcIcon name="user" size={{ width: 24, height: 24 }} />
        </ArcPopover>
        <ArcPopover
          content={
            <AuthConsumer>
              {({logout})=>showLogout(logout)}
            </AuthConsumer>
          }
          placement="bottom"
          trigger="click"
        >
          <ArcIcon name="user-profile" size={{ width: 14, height: 14 }} />
        </ArcPopover>
       
      </div>
    </div>
  );
}

export default TopNavigation;
